import type { FC, ReactNode } from 'react';
import type { LinksFunction } from 'remix';
import Logo, { links as logoLinks } from '../logo/Logo';
import Card from './Card';
import { Heading } from '@chakra-ui/react';

export interface Props {
    [x: string]: string | number | ReactNode;
}

export const links: LinksFunction = () => {
    return [...logoLinks()];
};

const LogoCard: FC<Props> = (props) => {
    return (
        <Card alignItems={'center'} innerPt={5} innerPb={5}>
            <Logo className="c-logo--alt"></Logo>
            <Heading
                color={'secondary'}
                textAlign={'center'}
                fontFamily="Roboto condensed"
                lineHeight={2}
                zIndex={1}
                margin={'10px'}
                fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}
                textTransform={'uppercase'}
            >
                Request Form
            </Heading>
            {props.children}
        </Card>
    );
};

export default LogoCard;
